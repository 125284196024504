import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

/**
 * Screens
 */

export const recommendationMenuNamingScreen = new analytics.Event({
  displayName: 'Recommendation menu naming screen',
  name: 'recommendation_menu_naming_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const recommendationNamingEmptyScreen = new analytics.Event({
  displayName: 'Recommendation naming empty screen',
  name: 'recommendation_naming_empty_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const recommendationDescriptionsScreen = new analytics.Event({
  displayName: 'Recommendation descriptions screen',
  name: 'recommendation_descriptions_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const recommendationDescriptionsEmptyScreen = new analytics.Event({
  displayName: 'Recommendation descriptions empty screen',
  name: 'recommendation_descriptions_empty_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const recommendationQuestionsScreen = new analytics.Event({
  displayName: 'Recommendation questions screen',
  name: 'recommendation_questions_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const recommendationQuestionsEmptyScreen = new analytics.Event({
  displayName: 'Recommendation questions empty screen',
  name: 'recommendation_questions_empty_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})

/**
 * Menus
 */

export const recommendationMenu = new analytics.Event({
  displayName: 'Recommendation menu',
  name: 'recommendation_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
