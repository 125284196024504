
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import Tabs, { ITab } from '@/components/tabs.vue'
import { AlertModule } from '@/modules/alert/store'
import { CommentModule } from '@/modules/comment/store'
import { DiagramModule } from '@/modules/diagram/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import * as recommendations from '../../helpers/recommendations'
import Descriptions from './descriptions.vue'
import DescriptionsEmpty from './descriptions-empty.vue'
import Naming from './naming.vue'
import NamingEmpty from './naming-empty.vue'
import QuestionsEmpty from './question-empty.vue'
import Questions from './questions.vue'

@Component({
  components: {
    Descriptions,
    DescriptionsEmpty,
    Menu,
    Naming,
    NamingEmpty,
    Questions,
    QuestionsEmpty,
    Tabs
  },
  name: 'RecommendationMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  commentModule = getModule(CommentModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu

  @Prop() readonly activator?: (() => HTMLElement | { $el: HTMLElement } | undefined)

  defaultTab = 'naming'

  get recommendationsMenu () {
    return this.$queryValue('recommendations_menu')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get objectRecommendations () {
    return recommendations.objectRecommendations({
      modelObjects: this.modelModule.objects
    })
  }

  get connectionRecommendations () {
    return recommendations.connectionRecommendations({
      diagramContents: this.diagramModule.diagramContents,
      diagrams: this.diagramModule.diagrams,
      modelConnections: this.modelModule.connections
    })
  }

  get diagramRecommendations () {
    return recommendations.diagramRecommendations({
      diagrams: this.diagramModule.diagrams
    })
  }

  get flowRecommendations () {
    return recommendations.flowRecommendations({
      flows: this.flowModule.flows
    })
  }

  get commentRecommendations () {
    return recommendations.commentRecommendations({
      activeComments: this.commentModule.activeComments
    })
  }

  get namingRecommendations () {
    return this.objectRecommendations.objectsWithoutName.length + this.diagramRecommendations.diagramsWithoutName.length + this.connectionRecommendations.connectionsWithoutName.length + this.flowRecommendations.flowsWithoutName.length
  }

  get descriptionsRecommendations () {
    return this.objectRecommendations.objectsWithoutDescription.length + this.diagramRecommendations.diagramsWithoutDescription.length + this.flowRecommendations.flowStepsWithoutDescription.length
  }

  get questionsRecommendations () {
    return this.commentRecommendations.questionCommentsOpen.length
  }

  get tabs (): ITab[] {
    return [
      {
        caption: this.namingRecommendations,
        id: 'naming',
        text: 'Naming',
        to: { query: this.$setQuery({ recommendations_menu: 'naming' }) }
      },
      {
        caption: this.descriptionsRecommendations,
        id: 'descriptions',
        text: 'Descriptions',
        to: { query: this.$setQuery({ recommendations_menu: 'descriptions' }) }
      },
      {
        caption: this.questionsRecommendations,
        id: 'questions',
        text: 'Questions',
        to: { query: this.$setQuery({ recommendations_menu: 'questions' }) }
      }
    ]
  }

  created () {
    const lastTab = localStorage.getItem('recommendationMenuTab')
    if (lastTab && this.tabs.some(o => o.id === lastTab)) {
      this.defaultTab = lastTab
    }
  }

  opened () {
    analytics.recommendationMenu.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  saveTab (id: string) {
    this.defaultTab = id
    localStorage.setItem('recommendationMenuTab', id)
  }
}
