
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'

import RecommendationsMenu from '../menu/index.vue'
import Progress from '../menu/progress.vue'

@Component({
  components: {
    Progress,
    RecommendationsMenu
  },
  name: 'RecommendationAppbar'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)

  open = false

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }
}
