
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import * as recommendations from '../../helpers/recommendations'

@Component({
  name: 'RecommendationMenuDescriptions'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  defaultSection = ''
  selectedIndex = 0

  iconUrlForTheme = iconUrlForTheme

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get objectRecommendations () {
    return recommendations.objectRecommendations({
      modelObjects: this.modelModule.objects
    })
  }

  get diagramRecommendations () {
    return recommendations.diagramRecommendations({
      diagrams: this.diagramModule.diagrams
    })
  }

  get flowRecommendations () {
    return recommendations.flowRecommendations({
      flows: this.flowModule.flows
    })
  }

  get sections () {
    return [
      {
        icon: '$fas-project-diagram',
        id: 'diagram',
        items: this.diagramRecommendations.diagramsWithoutDescription.map(o => ({
          click: async () => {
            await this.$replaceQuery({
              recommendations_menu: undefined
            })
            await this.$router.push({
              name: this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: this.currentVersionId
              },
              query: this.$setQuery({
                comment: undefined,
                connection: undefined,
                diagram: o.handleId,
                flow: undefined,
                flow_parent: undefined,
                flow_path: undefined,
                flow_step: undefined,
                model: this.modelModule.objects[o.modelId]?.handleId,
                object: undefined,
                x1: undefined,
                x2: undefined,
                y1: undefined,
                y2: undefined
              })
            })
          },
          subtitle: `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1).replace(/-/g, ' ').toLowerCase()}`,
          title: o.name
        })),
        title: 'Undescribed diagrams'
      },
      {
        icon: '$fas-sitemap',
        id: 'object',
        items: this.objectRecommendations.objectsWithoutDescription.map(o => ({
          click: async () => {
            await this.$replaceQuery({
              recommendations_menu: undefined
            })
            await this.$router.push({
              name: 'model-objects',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: this.currentVersionId
              },
              query: {
                focus: o.handleId,
                object: o.handleId,
                object_tab: 'details'
              }
            })
          },
          iconUrl: o.icon && iconUrlForTheme(o.icon),
          id: o.id,
          subtitle: `[${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}]`,
          title: o.name || `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`
        })),
        title: 'Undescribed objects'
      },
      {
        icon: '$custom-solid-repeat',
        id: 'flow',
        items: this.flowRecommendations.flowStepsWithoutDescription.map(o => {
          const diagram = this.diagramModule.diagrams[o.diagramId]
          const flow = this.flowModule.flows[o.flowId]
          return {
            click: async () => {
              await this.$replaceQuery({
                recommendations_menu: undefined
              })
              await this.$router.push({
                name: this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
                params: {
                  landscapeId: this.currentLandscapeId,
                  versionId: this.currentVersionId
                },
                query: this.$setQuery({
                  connection: undefined,
                  diagram: diagram.handleId,
                  drawer: 'expanded',
                  flow: flow.handleId,
                  flow_path: undefined,
                  flow_step: o.id,
                  model: this.modelModule.objects[diagram.modelId]?.handleId,
                  object: undefined
                })
              })
            },
            subtitle: `Step ${o.index + 1}`,
            title: o.name
          }
        }),
        title: 'Undescribed flow steps'
      }
    ].filter(o => o.items.length)
  }

  created () {
    const lastSection = localStorage.getItem('recommendationsMenuDescriptionsSectionKey')
    if (lastSection && this.sections.some(o => o.id === lastSection)) {
      this.defaultSection = lastSection
    } else {
      this.defaultSection = this.sections[0].id
    }
    this.selectedIndex = this.sections.findIndex(o => o.id === this.defaultSection)
  }

  mounted () {
    analytics.recommendationDescriptionsScreen.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  saveSection (id: string) {
    this.defaultSection = id
    localStorage.setItem('recommendationsMenuDescriptionsSectionKey', id)
  }
}
