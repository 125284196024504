import { Comment, Diagram, DiagramContent, Flow, ModelConnection, ModelObject } from '@icepanel/platform-api-client'

export const objectRecommendations = (props: {
  modelObjects: Record<string, ModelObject> | ModelObject[]
}) => {
  const objects = Object.values(props.modelObjects).filter(o => o.type !== 'root' && o.type !== 'group')
  const objectsWithoutName = objects.filter(o => !o.name)
  const objectsWithoutDescription = objects.filter(o => !o.description)

  return {
    objects,
    objectsWithoutDescription,
    objectsWithoutName
  }
}

export const connectionRecommendations = (props: {
  diagrams: Record<string, Diagram> | Diagram[]
  diagramContents: Record<string, DiagramContent> | DiagramContent[]
  modelConnections: Record<string, ModelConnection> | ModelConnection[]
}) => {
  const connections = Object.values(props.diagramContents).map(o => Object.values(o.connections)).flat()
  const connectionsWithoutName = connections.filter(o => !o.modelId || (props.modelConnections instanceof Array ? props.modelConnections.find(m => m.id === o.modelId) : props.modelConnections[o.modelId])?.name.includes('Unnamed'))

  return {
    connections,
    connectionsWithoutName
  }
}

export const flowRecommendations = (props: {
  flows: Record<string, Flow> | Flow[]
}) => {
  const flows = Object.values(props.flows)
  const flowSteps = flows.map(f => Object.values(f.steps).filter(s => s.type && !s.type.endsWith('-path') && s.type !== 'subflow').map(s => ({ ...s, diagramId: f.diagramId, flowId: f.id, name: f.name }))).flat()
  const flowsWithoutName = flows.filter(o => !o.name || o.name.match(/^Flow\s*\d*$/gi))
  const flowStepsWithoutDescription = flowSteps.filter(o => !o.description)

  return {
    flowSteps,
    flowStepsWithoutDescription,
    flows,
    flowsWithoutName
  }
}

export const diagramRecommendations = (props: {
  diagrams: Record<string, Diagram> | Diagram[]
}) => {
  const diagrams = Object.values(props.diagrams)
  const diagramsWithoutName = diagrams.filter(o => !o.name || o.name.match(/^Diagram\s*\d*$/gi))
  const diagramsWithoutDescription = diagrams.filter(o => !o.description)

  return {
    diagrams,
    diagramsWithoutDescription,
    diagramsWithoutName
  }
}

export const commentRecommendations = (props: {
  activeComments: Record<string, Comment> | Comment[]
}) => {
  const questionComments = Object.values(props.activeComments).filter(o => o.body.type === 'question') || []
  const questionCommentsOpen = questionComments.filter(o => o.body.status === 'open')

  return {
    questionComments,
    questionCommentsOpen
  }
}

const calculate = (props: {
  activeComments: Record<string, Comment> | Comment[]
  diagrams: Record<string, Diagram> | Diagram[]
  diagramContents: Record<string, DiagramContent> | DiagramContent[]
  flows: Record<string, Flow> | Flow[]
  modelConnections: Record<string, ModelConnection> | ModelConnection[]
  modelObjects: Record<string, ModelObject> | ModelObject[]
}) => {
  const { objects, objectsWithoutName, objectsWithoutDescription } = objectRecommendations(props)

  const { connections, connectionsWithoutName } = connectionRecommendations(props)

  const { flows, flowSteps, flowsWithoutName, flowStepsWithoutDescription } = flowRecommendations(props)

  const { diagrams, diagramsWithoutName, diagramsWithoutDescription } = diagramRecommendations(props)

  const { questionComments, questionCommentsOpen } = commentRecommendations(props)

  const totalItems = (objects.length * 2) + connections.length + flows.length + flowSteps.length + (diagrams.length * 2) + questionComments.length
  const totalRecommendations = objectsWithoutName.length + objectsWithoutDescription.length + connectionsWithoutName.length + flowsWithoutName.length + flowStepsWithoutDescription.length + diagramsWithoutName.length + diagramsWithoutDescription.length + questionCommentsOpen.length

  return totalItems === 0 ? 100 : 100 - Math.ceil((totalRecommendations / totalItems) * 100)
}

export const count = (props: {
  activeComments?: Record<string, Comment> | Comment[]
  diagrams?: Record<string, Diagram> | Diagram[]
  diagramContents?: Record<string, DiagramContent> | DiagramContent[]
  flows?: Record<string, Flow> | Flow[]
  modelConnections?: Record<string, ModelConnection> | ModelConnection[]
  modelObjects?: Record<string, ModelObject> | ModelObject[]
}) => {
  const count: Record<string, number> = {}

  if (props.activeComments && props.diagrams && props.diagramContents && props.flows && props.modelConnections && props.modelObjects) {
    const {
      objectsWithoutDescription,
      objectsWithoutName
    } = objectRecommendations({
      modelObjects: props.modelObjects
    })

    const {
      connectionsWithoutName
    } = connectionRecommendations({
      diagramContents: props.diagramContents,
      diagrams: props.diagrams,
      modelConnections: props.modelConnections
    })

    const {
      flowStepsWithoutDescription,
      flowsWithoutName
    } = flowRecommendations({
      flows: props.flows
    })

    const {
      diagramsWithoutDescription,
      diagramsWithoutName
    } = diagramRecommendations({
      diagrams: props.diagrams
    })

    const {
      questionCommentsOpen
    } = commentRecommendations({
      activeComments: props.activeComments
    })

    count.recommendation_score_objects_without_name_count = objectsWithoutName.length
    count.recommendation_score_objects_without_description_count = objectsWithoutDescription.length

    count.recommendation_score_connections_without_name_count = connectionsWithoutName.length

    count.recommendation_score_flows_without_name_count = flowsWithoutName.length
    count.recommendation_score_flow_steps_without_description_count = flowStepsWithoutDescription.length

    count.recommendation_score_diagrams_without_name_count = diagramsWithoutName.length
    count.recommendation_score_diagrams_without_description_count = diagramsWithoutDescription.length

    count.recommendation_score_comments_question_open_count = questionCommentsOpen.length

    count.recommendation_score = calculate({
      activeComments: props.activeComments,
      diagramContents: props.diagramContents,
      diagrams: props.diagrams,
      flows: props.flows,
      modelConnections: props.modelConnections,
      modelObjects: props.modelObjects
    })
  }

  return count
}

export default calculate
