
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import CommentListItem from '@/modules/comment/components/list-item.vue'
import { CommentModule } from '@/modules/comment/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import * as recommendations from '../../helpers/recommendations'

export const EXPANSION_PANEL_SECTION_KEY = 'recommendationsMenuQuestionsSectionKey'

@Component({
  components: {
    CommentListItem
  },
  name: 'RecommendationMenuQuestions'
})
export default class extends Vue {
  commentModule = getModule(CommentModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentOrganizationId () {
    return (this.$params.organizationId || this.currentLandscape?.organizationId)!
  }

  get currentLandscapeId () {
    return (this.$params.landscapeId || this.currentVersion?.landscapeId)!
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get commentRecommendations () {
    return recommendations.commentRecommendations({
      activeComments: this.commentModule.activeComments
    })
  }

  get openComments () {
    return this.commentRecommendations.questionCommentsOpen
  }

  mounted () {
    analytics.recommendationQuestionsScreen.track(this, {
      landscapeId: [this.currentLandscapeId],
      organizationId: [this.currentOrganizationId]
    })
  }
}
