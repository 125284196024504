
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { CommentModule } from '@/modules/comment/store'
import { DiagramModule } from '@/modules/diagram/store'
import { FlowModule } from '@/modules/flow/store'
import { ModelModule } from '@/modules/model/store'

import recommendationScore from '../../helpers/recommendations'

@Component({
  name: 'RecommendationMenuProgress'
})
export default class extends Vue {
  commentModule = getModule(CommentModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)

  percentage = -1
  percentageListener?: number

  get label () {
    return this.percentage > 0 ? `${this.percentage.toFixed(0)}%` : ''
  }

  get color () {
    if (this.percentage <= 24) {
      return 'warning'
    } else if (this.percentage <= 74) {
      return 'info'
    } else if (this.percentage <= 99) {
      return 'green'
    } else {
      return 'grey lighten-4'
    }
  }

  mounted () {
    this.percentageListener = window.setInterval(() => {
      this.calculatePercentage()
    }, 6000)
  }

  destroyed () {
    clearInterval(this.percentageListener)
  }

  calculatePercentage () {
    this.percentage = recommendationScore({
      activeComments: this.commentModule.activeComments,
      diagramContents: this.diagramModule.diagramContents,
      diagrams: this.diagramModule.diagrams,
      flows: this.flowModule.flows,
      modelConnections: this.modelModule.connections,
      modelObjects: this.modelModule.objects
    })
  }
}
